<template>
  <div class="w-full mt-40">
    <div class="bg-blue-50 border border-blue-300 p-10 w-2/3 mx-auto text-center">
      <h1 class="text-blue-500 flex items-center justify-center mb-4">
        <icon name="warning" class="mr-2" />the platform is currently unavailable
      </h1>
      <button class="btn-primary btn-solid" @click="logout">return to login</button>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Icon from '@/components/ui/Icon';

  export default {
    name: 'ProfileDisabled',
    components: { Icon },
    mixins: [NotifyMixin],
    methods: {
      async logout() {
        try {
          await Auth.signOut();

          this.$router.replace({
            name: 'home',
          });
        } catch (error) {
          this.notifyError('error signing out: ', error);
        }
      }
    },
  };
</script>
